.page-index {
  .list-schedule__item:nth-child(1) {
    background: url(../img/index/line-01.png) no-repeat top left, url(../img/index/line-01.png) no-repeat top right;
  }
  .list-schedule__item {
    background: url(../img/index/line-01.png) no-repeat top right;
  }
  .sec-schedule {
    margin-bottom: 32px;
    @media #{$media-sp} {
      margin-bottom: 12px;
    }
  }
  .topics-banner {
    margin-bottom: 62px;
  }
  .blog-banner {
    padding: 0 1.5rem 0 4rem;
    @media #{$media-sp} {
      padding: 0 1.5rem 0 1.5rem;
    }
    .title {
      display: inline-block;
      align-items: center;
      margin: 0 3rem 0 0;
      font-size: 2rem;
      font-weight: bold;
      @media #{$media-sp} {
        display: block;
        padding: 0 0 1rem 0;
        width: 100%;
        font-size: 2.2rem;
        text-align: center;
      }
    }
    .date {
      display: inline-block;
      align-items: center;
      margin: 0.3rem 2rem 0 0;
      font-family: " Overpass", sans-serif;
      font-size: 1.4rem;
      line-height: 25px;
      vertical-align: middle;
      @media #{$media-sp} {
        display: inline-block;
        margin: 0.1rem 5px 0 0;
      }
    }
    .category {
      display: inline-block;
      align-items: center;
      justify-content: center;
      margin: 0 3rem 0 0;
      padding: 0 1rem;
      border: #ac8667 solid 1px;
      border-radius: 0.1rem;
      font-size: 12px;
      line-height: 25px;
      color: #ac8667;
      vertical-align: middle;
      @media #{$media-sp} {
        display: inline-block;
        margin: 0 0;
        padding: 0;
        width: 10rem;
        line-height: 20px;
        text-align: center;
      }
    }
    .txt {
      display: flex;
      align-items: center;

      width: 63%;
      font-size: 1.6rem;
      color: #fd8422;
      @media #{$media-sp} {
        padding: 0.7rem 0 0;
        font-size: 1.6rem;
      }
    }
  }
  .new-topics {
    padding: 3rem 0 3rem 3.6rem;
    background: url(../img/index/line-02.png) repeat-x left 40px bottom,
      url(../img/index/line-02.png) repeat-x left 40px top;
    @media #{$media-sp} {
      padding: 0.5rem 0 2rem 0rem;
      background: url(../img/index/line-02.png) repeat-x left 40px bottom;
    }
  }
}
