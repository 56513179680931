// 下層ページの共通要素

.page-event_index {
  .add-line {
    margin: 10rem auto 0 auto;
    padding: 3rem 6rem;
    width: 90%;
    min-width: 1080px;
    font-size: 1.5rem;
    background: url(../img/line.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @media #{$media-sp} {
      margin: 5rem auto 0 auto;
      padding: 3rem 2rem;
      width: 100%;
      min-width: auto;
      background: url(../img/line-sp.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }
  .left-box {
    padding: 0 3rem 0 0;
    width: 55%;
    line-height: 1.8;
    @media #{$media-sp} {
      padding: 0 0 0 0;
      width: 100%;
    }
  }
  .right-box {
    padding: 3rem 0 0 0;
    width: 45%;
    text-align: right;
    @media #{$media-sp} {
      padding: 0 0 0 0;
      width: 100%;
    }
  }
  .add-line-title {
    position: relative;
    padding: 3rem 0 6rem;
    font-size: 2.2rem;
    line-height: 1.7;
    @media #{$media-sp} {
      padding: 1.5rem 0 4rem;
      font-size: 2.4rem;
    }
    &::after {
      position: absolute;
      right: 35%;
      content: url("../img/add-ten.png");
      @media #{$media-sp} {
        right: 0%;
        bottom: 3rem;
      }
    }
    &::before {
      position: absolute;
      bottom: 2.2rem;
      left: 2rem;
      content: url("../img/add-ten2.png");
      @media #{$media-sp} {
        bottom: 0.5rem;
        left: 0rem;
      }
    }
  }
  .add-line-txt {
    @media #{$media-sp} {
      padding: 0 0 0 0;
    }
  }
  .s-title {
    padding: 2rem 0 1rem 0;
  }
  .bottom-t {
    padding: 0 0 3rem 0;
    @media #{$media-sp} {
      padding: 0 0 0 0;
    }
  }
}
.page-contact_index {
  .to-contact {
    select {
      margin: 0.4rem 0 0 0;
      padding: 0.7rem;
      width: 20rem;
      @media #{$media-sp} {
        width: 100%;
      }
    }
  }
  .sec-contact .contact-check__list dt {
    margin-top: 10px;
    // width: 154px;
  }
}
